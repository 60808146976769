import {PolymerElement, html} from '@polymer/polymer';

import 'web-animations-js/web-animations-next.min.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import '@polymer/iron-meta/iron-meta.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@polymer/paper-dropdown-menu/paper-dropdown-menu.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-spinner/paper-spinner.js';
import '@polymer/paper-toast/paper-toast.js';
import '@google-web-components/google-signin/google-signin.js';

import './ava-ajax.js'
import './ava-news-item.js'


class NewsDashboard extends PolymerElement {
  static get is() { return 'ava-news-dashboard'; }
  static get properties() {
    return {
      data_: Object,
      loading_: {
        type: Boolean,
        value: true,
      },
      unreadCount_: Number,

      signedIn_: {
        type: Boolean,
        value: false,
        observer: 'onSignIn_',
      },

      token_: {
        type: String,
        value: '',
        notify: true,
      },
      itemOrder_: {
        type: String,
        value: 'NEWEST_FIRST',
      }
    };
  }

  static get template() {
    return html`
      <style>
        :host {
          display: block;
        }
        .unreadCounter {
          position: fixed;
          top: 100%;
          left: 100%;
          transform: translate(-100%, -100%);
          z-index: 1;
          color: red;
        }
      </style>
      <iron-meta key="ava-access-token" value="[[token_]]"></iron-meta>
      <paper-dialog modal id="errorDialog">
        <h2>Network error</h2>
      </paper-dialog>
      <paper-toast id="toast"></paper-toast>

      <div hidden="[[signedIn_]]">
        <google-signin
          brand="google" width="wide" signed-in="{{signedIn_}}" scopes="email"
          client-id="729745225938-2ih5t5fq3vqokouff2r49q5nilp0b7fo.apps.googleusercontent.com">
        </google-signin>
      </div>
      <div
        hidden="[[!signedIn_]]" on-ava-ajax-error="onNetworkError_"
        on-ava-ajax-retry="onNetworkRetry_">
        <ava-ajax
          id="request" url="/v1/dashboard/get_articles" last-response="{{data_}}"
          loading="{{loading_}}" on-response="updateUnreadCount_">
        </ava-ajax>
        <div hidden="[[!loading_]]">
          <paper-spinner active="[[loading_]]"></paper-spinner>
        </div>
        <div style="float: right; display:flex; align-items: end;">
          <paper-input id="searchTerm" label="search term"
            on-keydown="okSearchInputKeydown_">
          </paper-input>
          <paper-dropdown-menu no-label-float>
            <paper-listbox
              slot="dropdown-content" attr-for-selected="item"
              selected="[[itemOrder_]]" on-selected-changed="onItemOrderChanged_">
              <paper-item item="NEWEST_FIRST">newest unread first</paper-item>
              <paper-item item="OLDEST_FIRST">oldest unread first</paper-item>
              <paper-item item="HIGHEST_SIMILARITY">highest similarity</paper-item>
              <paper-item item="CLICK_PROBABILITY">click probability</paper-item>
            </paper-listbox>
          </paper-dropdown-menu>
        </div>
        <div hidden="[[loading_]]">
          <div hidden="[[data_.articles.length]]">
            <h3>No new stories</h3>
          </div>
          <div hidden="[[!data_.articles.length]]">
            Stories :
            <div class="container vertical">
              <template is="dom-if" if="[[!loading_]]" restamp>
                <template is="dom-repeat" items="[[data_.articles]]">
                  <ava-news-item
                    article="{{item}}" articles="{{data_.articles}}"
                    on-ava-update-unread-counter="updateUnreadCount_">
                  </ava-news-item>
                </template>
              </template>
            </div>
            <div class="unreadCounter">
              [[unreadCount_]]
              <div hidden="[[!data_.more_available]]">
                +
              </div>
            </div>

            <!-- allow to scroll thru last item -->
            <div style="height: 100vh;"></div>
          </div>
        </div>
      </div>
    `;
  }

  ready() {
    super.ready();
    // TODO: remove lister on deattach
    window.addEventListener('scroll', () => {
      this.update_();
    });
    // TODO: remove interval on deattach
    this.intervalId_ = setInterval(() => {
      this.updateToken_();
    }, 10 * 1000);
  }

  update_() {
    for (let n of this.root.querySelectorAll('ava-news-item')) {
      n.updateState();
    }
  }

  updateUnreadCount_() {
    let n = 0;
    for (let o of this.data_.articles) {
      if (!o.read) {
        n++;
      }
    }
    this.unreadCount_ = n;
  }

  refreshNews_(searchTerm) {
    this.updateToken_();
    if (this.signedIn_) {
      const req = {order: this.itemOrder_};
      if (searchTerm) {
        req.searchTerm = searchTerm;
      }
      this.$.request.body = JSON.stringify(req);
      this.$.request.generateRequest();
    }
  }

  updateToken_() {
    if (this.signedIn_) {
      this.token_ = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().id_token;
    }
  }

  onNetworkError_(e) {
    console.log(e);

    this.$.errorDialog.open();
  }

  onNetworkRetry_(e) {
    this.$.toast.text = 'Retrying... ' + e.detail;
    this.$.toast.open();
  }

  onItemOrderChanged_(e) {
    if (!e.detail.value) {
      return;
    }
    this.itemOrder_ = e.detail.value;
    this.refreshNews_();
  }

  onSignIn_() {
    this.updateToken_();
    if (this.signedIn_) {
      this.refreshNews_();
    }
  }

  okSearchInputKeydown_(e) {
    if (e.keyCode !== 13) {
      return;
    }
    this.refreshNews_(this.$.searchTerm.value);
  }
}

window.customElements.define(NewsDashboard.is, NewsDashboard);
