import {PolymerElement, html} from '@polymer/polymer';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-meta/iron-meta.js'


class AvaAjax extends PolymerElement {
  static get is() { return 'ava-ajax'; }
  static get properties() {
    return {
      url: String,
      body: String,

      /* because of retried request, can be STALE. */
      lastResponse: {
        type: Object,
        notify: true,
      },
      loading: {
        type: Boolean,
        value: false,
        notify: true,
      },

      accessToken_: String,
      authHeaders_: {
        type: Object,
        computed: 'computeAuthHeaders_(accessToken_)',
      },
    };
  }

  static get template() {
    return html`
      <iron-meta id="meta"></iron-meta>
      <iron-ajax
        id="request" url="[[url]]" body="[[body]]" loading="{{loading}}"
        last-response="{{lastResponse}}" method="POST"
        handle-as="json" headers="[[authHeaders_]]">
      </iron-ajax>
    `;
  }

  generateRequest() {
    const params = {
      url: this.url,
      body: this.body,
    };

    let i = 0, k = 1;
    const retry = () => {
      this.url = params.url;
      this.body = params.body;

      this.retportRetry(i);
      generateRequest();
    };
    const errorHandler = (e) => {
      if (i > 5) {
        this.reportNetworkError_(e);
        return;
      }
      setTimeout(retry, 300 * k);

      i++;
      k *= 2;
    }
    const generateRequest = () => {
      this.accessToken_ = this.$.meta.byKey('ava-access-token');
      const request = this.$.request.generateRequest();

      request.completes.catch(errorHandler);
    };

    generateRequest();
  }

  computeAuthHeaders_(accessToken) {
    if (!accessToken) {
      return {};
    }
    return {
      'Authorization': 'Bearer ' + accessToken,
    };
  }

  reportNetworkError_(e) {
    console.log(e);
    const event = new CustomEvent('ava-ajax-error', {
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(event);
  }

  retportRetry(i) {
    const event = new CustomEvent('ava-ajax-retry', {
      bubbles: true,
      composed: true,
      detail: i,
    });
    this.dispatchEvent(event);
  }
}

window.customElements.define(AvaAjax.is, AvaAjax);
