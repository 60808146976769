import {PolymerElement, html} from '@polymer/polymer';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import '@polymer/iron-icons/iron-icons.js';
import '@polymer/paper-card/paper-card.js';
import '@polymer/paper-icon-button/paper-icon-button.js';

import './ava-ajax.js'


class NewsItem extends PolymerElement {
  static get is() { return 'ava-news-item'; }
  static get properties() {
    return {
      article: Object,
      articles: {
        type: Array,
        notify: true,
      },
      body_: String,

      keepUnread_: {
        type: Boolean,
        value: false,
      }
    };
  }

  static get template() {
    return html`
      <style is="custom-style" include="iron-flex"></style>
      <style is="custom-style" include="iron-flex-factors">
        div.unread {
          font-size: 1.5em;
          font-weight: bold;
          display: inline;
        }
        div.read {
          display: inline;
          font-size: 0.75em;
        }
        div.similarArticleDiv:not(:last-of-type) {
          border-bottom: 1px solid;
          border-color: var(--paper-grey-400)
          margin-bottom: 5px;
        }
        @media (max-width: 767px)   {
          .placeholder {
            min-width: 5%;
          }
        }
        @media (min-width: 767px)   {
          .placeholder {
            min-width: 30%;
          }
        }
      </style>

      <ava-ajax
        id="markRead" url="/v1/dashboard/mark_article_read" body="[[body_]]">
      </ava-ajax>
      <ava-ajax
        id="markUnread" url="/v1/dashboard/mark_article_unread" body="[[body_]]">
      </ava-ajax>
      <ava-ajax
        id="markOpened" url="/v1/dashboard/mark_article_opened" body="[[body_]]">
      </ava-ajax>

      <ava-ajax
        id="markSimilar" url="/v1/dashboard/mark_as_similar" body="[[body_]]">
      </ava-ajax>
      <ava-ajax
        id="markIrrelevant" url="/v1/dashboard/mark_as_irrelevant" body="[[body_]]">
      </ava-ajax>

      <paper-card style="margin-top: 10px; margin-left: 2.5%; width: 95%;">
        <div class="card-content vertical layout">
          <div>
            <div on-tap="openLink_" class$="[[readToClass_(article.read)]]">
              [[article.header]]
            </div>
            <paper-icon-button
              icon="icons:markunread" on-tap="onKeepUnreadButtonTap_" alt="keep unread"
              style="float:right">
            </paper-icon-button>
            <paper-icon-button
              icon="icons:done-all" on-tap="markAllRelatedRead_" alt="mark as read"
              style="float:right" hidden=[[!article.similar.length]]>
            </paper-icon-button>
          </div>
          <div class="layout horizontal center">
            <div class="placeholder"></div>
            <div class="card-content vertical layout">
              <template is="dom-repeat" items="[[article.similar]]">
                <div class="layout horizontal center similarArticleDiv">
                  <paper-icon-button
                    icon="icons:check" on-tap="onItemSimilarButtonTap_" alt="mark as similar">
                  </paper-icon-button>
                  <paper-icon-button
                    icon="icons:error" on-tap="onItemNotSimilarButtonTap_" alt="mark as irrelevant">
                  </paper-icon-button>
                  <div class="flex-6" on-tap="openSimilar_">[[item.header]]</div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </paper-card>
    `;
  }

  static get observers() {
    return [
      'onReadChanged(article.read)',
      'onOpenedChanged(article.opened)',
    ];
  }

  onReadChanged() {
    if (!this.article.read) {
      return;
    }
    this.body_ = JSON.stringify({
      article_id: this.article.id,
    });
    this.$.markRead.generateRequest();

    this.dispatchEvent(new CustomEvent('ava-update-unread-counter'));
  }

  onOpenedChanged() {
    if (!this.article.opened) {
      return;
    }
    this.body_ = JSON.stringify({
      article_id: this.article.id,
    });
    this.$.markOpened.generateRequest();
  }

  updateState() {
    if (this.offsetTop > window.scrollY) {
      return;
    }
    if (this.article.read) {
      return;
    }

    this.markAsRead_();
  }

  openLink_() {
    this.markAsRead_();
    window.open(this.article.id);
    this.markAsOpened_();
  }

  markAsRead_() {
    if (this.keepUnread_) {
      return;
    }
    this.set('article.read', true);
  }

  markAsUnread_() {
    this.body_ = JSON.stringify({
      article_id: this.article.id,
    });
    this.$.markUnread.generateRequest();
    this.set('article.read', false);
  }

  markAsOpened_() {
    this.set('article.opened', true);
  }

  onItemSimilarButtonTap_(e) {
    e.stopPropagation();

    var other = e.model.item;

    this.markSimilarRead_(other);

    this.body_ = JSON.stringify({
      article_id1: this.article.id,
      article_id2: other.id,
    });

    this.$.markSimilar.generateRequest();
  }

  onItemNotSimilarButtonTap_(e) {
    e.stopPropagation();

    var other = e.model.item;

    this.markSimilarRead_(other);

    this.body_ = JSON.stringify({
      article_id1: this.article.id,
      article_id2: other.id,
    });

    this.$.markIrrelevant.generateRequest();
  }

  onKeepUnreadButtonTap_(e) {
    this.keepUnread_ = true;
    this.markAsUnread_();
  }

  markSimilarRead_(other) {
    for (let i = 0; i < this.articles.length; i++) {
      let a = this.articles[i];
      if (a.id != other.id) {
        continue;
      }
      this.set('articles.' + i + '.read', true);
    }
  }

  markAllRelatedRead_() {
    for (const a of this.article.similar) {
      this.markSimilarRead_(a);
    }
    this.set('article.read', true);
  }

  markSimilarOpened_(other) {
    for (let i = 0; i < this.articles.length; i++) {
      let a = this.articles[i];
      if (a.id != other.id) {
        continue;
      }
      this.set('articles.' + i + '.opened', true);
    }
  }

  openSimilar_(e) {
    let other = e.model.item;
    window.open(other.id);

    this.markSimilarOpened_(other);
    this.markSimilarRead_(other);
  }

  readToClass_(read) {
    if (read) {
      return 'read';
    }
    return 'unread';
  }
}

window.customElements.define(NewsItem.is, NewsItem);
